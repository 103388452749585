import * as React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';

import Navbar from '../components/navbar';
import HeroInner from '../components/hero-inner';
import BlogContent from '../components/blog-content';
import SIDEBAR from '../components/s-i-d-e-b-a-r';
import Footer from '../components/footer';

const BlogPost = styled.section`
	align-self: stretch;
	background-color: var(--dominant);
	box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: var(--padding-71xl) var(--padding-101xl);
	gap: var(--gap-81xl);
	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
`;
const BlogRoot = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: center;
	font-size: var(--font-size-2xl);
	color: var(--dominant);
	// font-family: var(--text-tiny-link);
`;
const BlogOrderedList = styled.ol`
	margin: 0;
	font-size: inherit;
	padding-left: var(--padding-5xl);
	margin-block-end: 1.875rem;
`;
const BlogListItem = styled.li`
	margin-bottom: 12px;
	b {
		display: flex;
	}
`;

const Blog = () => {
	return (
		<BlogRoot>
			<Navbar
				continLogo2a1='/continlogo2a-1.svg'
				accountSection={false}
				hamburgerMenu='/notification.svg'
				showHamburgerMenuIcon
				notificationBell='/notification1.svg'
				profilePicture='/top_avatar@2x.png'
				actionButton='Get in Touch'
				iconChevronRight='/icon--chevronright@2x.png'
				showActionButton={false}
				showActionButtonLink={false}
			/>
			<HeroInner
				headerTitle={`The Role of Culture in Compliance: Building a Security-Conscious Organization`}
				tagDate='Culture  -  Feb 29, 2024'
				headerImage='/post-image1@2x.png'
			/>
			<BlogPost>
				<BlogContent
					introKicker={
						'In the realm of cybersecurity, compliance extends beyond a checklist of technical measures — its about fostering a culture that champions security at every level of your organization. '
					}
					pullQuote='As we navigate an era where cyber threats are more sophisticated than ever, understanding and cultivating a security-conscious culture becomes paramount.'
					mainContent={
						<>
							<Typography variant='h3' sx={{ fontFamily: 'Oswald' }}>
								The Cultural Imperative.
							</Typography>
							<BlogOrderedList>
								<BlogListItem>
									<b>A Collective Responsibility:</b>
									Security is not the sole responsibility of the IT department; it's a
									collective duty that permeates every department and employee. A
									security-conscious culture ensures that every team member understands
									their role in safeguarding sensitive information.
								</BlogListItem>
								<BlogListItem>
									<b>Awareness as a Defense:</b>
									In a world where phishing attacks and social engineering are prevalent,
									cultivating awareness becomes a potent defense. A security-conscious
									culture prioritizes ongoing training and awareness programs, empowering
									employees to recognize and respond to potential threats.
								</BlogListItem>
								<BlogListItem>
									<b>Transparent Communication:</b>
									Open lines of communication are the bedrock of a security-conscious
									organization. When employees feel encouraged to report security
									concerns without fear of reprisal, the organization can address
									potential issues swiftly, preventing them from escalating.
								</BlogListItem>
							</BlogOrderedList>
							<Typography variant='h3' sx={{ fontFamily: 'Oswald' }}>
								Building Blocks of a Security-Conscious Culture
							</Typography>
							<BlogOrderedList>
								<BlogListItem>
									<b>Leadership Example:</b>
									The tone for a security-conscious culture is set at the top. When
									leaders prioritize and exemplify security practices, it cascades
									throughout the organization. Leadership commitment sends a powerful
									message that security is not negotiable.
								</BlogListItem>
								<BlogListItem>
									<b>Continuous Training:</b>
									Cyber threats evolve, and so should your team's knowledge. Regular
									training sessions keep employees informed about the latest threats and
									equip them with the skills to navigate the digital landscape securely.
								</BlogListItem>
								<BlogListItem>
									<b>Incentivizing Security Practices:</b>
									Recognize and reward security-conscious behavior. Whether through
									employee recognition programs or other incentives, acknowledge and
									celebrate a commitment to security within your organization.
								</BlogListItem>
							</BlogOrderedList>
							<Typography
								component='h3'
								variant='h3'
								sx={{ fontFamily: 'Oswald', marginBlockEnd: '1rem' }}
							>
								Embedding Security in the DNA
							</Typography>

							<Typography component='p' variant='p'>
								Building a security-conscious organization goes beyond compliance—it's
								about embedding security practices in the DNA of your corporate culture.
								It's an investment in resilience, a commitment to protecting not just
								data but the trust of your clients, partners, and stakeholders.
							</Typography>
							<Typography component='p' variant='p'>
								<b>Next Steps:</b>
								As we embark on this journey to explore the intersection of culture and
								compliance, join us in the coming weeks for actionable insights and
								strategies. Learn how to transform your organization into a fortress
								where security is not just a measure but a way of life.
							</Typography>
						</>
					}
				/>
				<SIDEBAR />
			</BlogPost>

			<Footer />
		</BlogRoot>
	);
};

export default Blog;
